import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import AnonymousLayout from "../../layouts/AnonymousLayout/AnonymousLayout";
import LoginPage from "../../pages/form/LoginPage/LoginPage";
import AuthorizedLayout from "../../layouts/AuthorizedLayout/AuthorizedLayout";
import HomePage from "../../pages/form/HomePage/HomePage";
import PrivateRoute from "../../app/routing/PrivateRoute";
import {Suspense} from "react";
import LoadingScreen from "../../components/ui/components/LoadingScreen/LoadingScreen";
import {Navigate} from "react-router-dom";
import ProfilePage from "../../pages/form/ProfilePage/ProfilePage";
import UserLogGridPage from "../../pages/grid/UserLogGridPage/UserLogGridPage";
import AdminGridPage from "../../pages/grid/AdminGridPage/AdminGridPage";
import SettingGridPage from "../../pages/grid/SettingGridPage/SettingGridPage";
import UserGridPage from "../../pages/grid/UserGridPage/UserGridPage";
import BannerGridPage from "../../pages/grid/BannerGridPage/BannerGridPage";
import PostCategory from "../../components/pages/grid/PostCategory/PostCategory";
import PostSourceGridPage from "../../pages/grid/PostSourceGridPage/PostSourceGridPage";
import PageGridPage from "../../pages/grid/PageGridPage/PageGridPage";
import PostGridPage from "../../pages/grid/PostGridPage/PostGridPage";
import ProblemUserGridPage from "../../pages/grid/ProblemUserGridPage/ProblemUserGridPage";
import ActiveUsersChartPage from "../../pages/chart/ActiveUsersChartPage/ActiveUsersChartPage";
import UsersChartPage from "../../pages/chart/UsersChartPage/UsersChartPage";
import AdminPage from "../../pages/form/AdminPage/AdminPage";
import SettingPage from "../../pages/form/SettingPage/SettingPage";
import NewsGrabberSettingPage from "../../pages/form/SettingPage/NewsGrabberSettingPage";
import UserPage from "../../pages/form/UserPage/UserPage";
import BannerPage from "../../pages/form/BannerPage/BannerPage";
import PostCategoryPage from "../../pages/form/PostCategoryPage/PostCategoryPage";
import PostSourcePage from "../../pages/form/PostSourcePage/PostSourcePage";
import PagePage from "../../pages/form/PagePage/PagePage";
import PostPage from "../../pages/form/PostPage/PostPage";
import AccountGridPage from "../../pages/grid/AccountGridPage/AccountGridPage";
import AccountPage from "../../pages/form/AccountPage/AccountPage";
import PollGridPage from "../../pages/grid/PollGridPage/PollGridPage";
import PollPage from "../../pages/form/PollPage/PollPage";
import LinkPage from "../../pages/form/LinkPage/LinkPage";
import LinkGridPage from "../../pages/grid/LinkGridPage/LinkGridPage";
import HomeOwnerEripGridPage from "../../pages/grid/HomeOwnerEripGridPage/HomeOwnerEripGridPage";
import HomeOwnerPollGridPage from "../../pages/grid/HomeOwnerPollGridPage/HomeOwnerPollGridPage";
import HomeOwnerPollPage from "../../pages/form/HomeOwnerPollPage/HomeOwnerPollPage";
import HomeOwnerEripPage from "../../pages/form/HomeOwnerEripPage/HomeOwnerEripPage";
import PostImageTemplateGridPage from "../../pages/grid/PostImageTemplateGridPage/PostImageTemplateGridPage";
import PostImageTemplatePage from "../../pages/form/PostImageTemplatePage/PostImageTemplatePage";

export default [
    {
        element: <Suspense fallback={<LoadingScreen/>}><BaseLayout/></Suspense>,
        children: [
            {
                element: <AnonymousLayout/>,
                children: [
                    {
                        path: '/login',
                        element: <LoginPage/>
                    },
                    {
                        path: '*',
                        element: <Navigate to='' replace/>
                    }
                ]
            },
            {
                element:
                    (<PrivateRoute>
                        <AuthorizedLayout/>
                    </PrivateRoute>),
                children: [
                    {
                        path: '',
                        element: <HomePage/>
                    },
                    {
                        path: '/news/posts',
                        element: <PostGridPage/>
                    },
                    {
                        path: '/news/posts/post/:id?/:category?',
                        element: <PostPage />
                    },
                    {
                        path: '/news/pages',
                        element: <PageGridPage/>
                    },
                    {
                        path: '/news/pages/page/:id?',
                        element: <PagePage />
                    },

                    {
                        path: '/news/sources',
                        element: <PostSourceGridPage/>
                    },
                    {
                        path: '/news/sources/source/:id?',
                        element: <PostSourcePage />
                    },
                    {
                        path: '/news/imagetemplates',
                        element: <PostImageTemplateGridPage/>
                    },
                    {
                        path: '/news/imagetemplates/imagetemplate/:id?',
                        element: <PostImageTemplatePage />
                    },
                    {
                        path: '/news/categories',
                        element: <PostCategory/>
                    },
                    {
                        path: '/news/categories/category/:id?',
                        element: <PostCategoryPage />
                    },
                    {
                        path: '/news/banners',
                        element: <BannerGridPage/>
                    },
                    {
                        path: '/news/banners/banner/:id?',
                        element: <BannerPage/>
                    },
                    {
                        path: '/users',
                        element: <UserGridPage/>
                    },
                    {
                        path: '/users/user/:id?',
                        element: <UserPage/>
                    },
                    {
                        path: '/users/logs',
                        element: <UserLogGridPage/>
                    },
                    {
                        path: '/users/accounts',
                        element: <AccountGridPage />
                    },
                    {
                        path: '/users/accounts/account/:id?',
                        element: <AccountPage />
                    },
                    {
                        path: '/users/polls',
                        element: <PollGridPage />
                    },
                    {
                        path: '/users/polls/poll/:id?',
                        element: <PollPage />
                    },
                    {
                        path: '/management/admins',
                        element: <AdminGridPage/>
                    },
                    {
                        path: '/management/admins/admin/:id?',
                        element: <AdminPage/>
                    },
                    {
                        path: '/management/settings',
                        element: <SettingGridPage/>
                    },
                    {
                        path: '/management/links',
                        element: <LinkGridPage/>
                    },
                    {
                        path: '/management/links/link/:id?',
                        element: <LinkPage />
                    },
                    {
                        path: '/management/homeownerpolls',
                        element: <HomeOwnerPollGridPage/>
                    },
                    {
                        path: '/management/homeownerpolls/homeownerpoll/:id?',
                        element: <HomeOwnerPollPage />
                    },
                    {
                        path: '/management/homeownererips',
                        element: <HomeOwnerEripGridPage/>
                    },
                    {
                        path: '/management/homeownererips/homeownererip/:id?',
                        element: <HomeOwnerEripPage />
                    },
                    {
                        path: '/management/settings/setting/:id?',
                        element: <SettingPage/>
                    },
                    {
                        path: '/management/settings/grabber',
                        element: <NewsGrabberSettingPage/>
                    },
                    {
                        path: '/analytics/users',
                        element: <UsersChartPage/>
                    },
                    {
                        path: '/analytics/users/active',
                        element: <ActiveUsersChartPage/>
                    },
                    {
                        path: '/analytics/users/problem',
                        element: <ProblemUserGridPage/>
                    },
                    {
                        path: '/profile/me',
                        element: <ProfilePage/>
                    },
                    {
                        path: '*',
                        element: <Navigate to='' replace/>
                    }
                ]
            }
        ]
    }
]
